import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import findIndex from 'lodash/findIndex'
import min from 'lodash/min'
import max from 'lodash/max'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

export const getSelectedValues = list =>
  list
    .reduce((c, l) => {
      if (!l?.values[0]?.values) {
        let selected = false
        for (let i = 0; i < l.values.length; i += 1) {
          if (l.values[i].selected) {
            selected = true
            break
          }
        }
        if (selected) return c.concat(l.values)
        return c.concat([])
      }
      return c.concat(getSelectedValues(l.values))
    }, [])
    .filter(l => !isEmpty(l))

export const everySelected = (...lists) =>
  lists.every(list => list.every(item => item.selected))

export const everyUnselected = (...lists) =>
  lists.every(list => list.every(item => !item.selected))

export const selectOnlyOneById =
  (payload, key = 'values') =>
  item => {
    if (!item[key]) {
      if (item.id === payload.id) return {...item, selected: true}
      return {...item, selected: false}
    }
    return {...item, [key]: item[key].map(selectOnlyOneById(payload, key))}
  }

export const selectOnlyOneByName = payload => item => {
  if (!item.values) {
    if (item.name === payload.name) return {...item, selected: true}
    return {...item, selected: false}
  }
  return {...item, values: item.values.map(selectOnlyOneByName(payload))}
}

export const selectOnlyOneByIndex = payload => (item, index) => {
  if (index === payload) return {...item, selected: true}
  return {...item, selected: false}
}

export const selectOneById = payload => item => {
  if (item.id === payload.id) return {...item, selected: true}
  return item
}

export const selectManyByIds = payload => item => {
  if (payload?.includes(item.id)) return {...item, selected: true}
  return item
}

export const unselectOneById = payload => item => {
  if (item.id === payload.id) return {...item, selected: false}
  return item
}

export const unselectManyByIds = payload => item => {
  if (payload?.includes(item.id)) return {...item, selected: false}
  return item
}

export const selectOnlyInIds =
  (_ids, key = 'values') =>
  item => {
    const ids = Array.isArray(_ids) ? _ids : [_ids]
    if (!item[key]) {
      if (ids.includes(item.id)) return {...item, selected: true}
      return {...item, selected: false}
    }
    return {...item, [key]: item[key].map(selectOnlyInIds(ids, key))}
  }

export const selectAll = item => ({...item, selected: true})

export const unselectAll = item => ({...item, selected: false})

export const getSelectedId = list =>
  Number(
    list
      .filter(item => item.selected)
      .map(item => item.id)
      .join(),
  )

export const getSelectedIds = list =>
  list.filter(item => item.selected).map(item => item.id)

export const getSelectedOne = list => {
  const [item] = list.filter(item => item.selected)
  return isObject(item) ? item : {}
}

export const extent = (data, accessor) => [
  min(data.map(accessor)),
  max(data.map(accessor)),
]

export const recursiveMap = (items, mapper, key = 'values') =>
  map(items, item => {
    if (!item[key]) return mapper(item)
    const values = recursiveMap(item[key], mapper, key)
    return {...mapper(item), [key]: values}
  })

export const toCache = (list, key) =>
  isArray(list)
    ? list.reduce((acc, item) => {
        acc[item[key]] = item
        return acc
      }, {})
    : {}

const toSelected = list =>
  list.map(l => ({
    id: isString(l) ? l : l.id,
    name: isString(l) ? l : l.name,
    selected: true,
    type: list.name,
  }))

export const mergeSelected = (listA, listB) => {
  const selected = toSelected(listA, listA.name)
  const cache = toCache(selected, 'id')
  const results = listB.map(b => {
    const cached = cache[isString(b) ? b : b.id]
    if (cached) {
      const index = findIndex(selected, {id: cached.id})
      selected.splice(index, 1)
      return cached
    }
    return {
      id: isString(b) ? b : b.id,
      name: isString(b) ? b : b.name,
      selected: false,
      type: listA.name,
    }
  })
  return [...selected, ...results]
}

export const flattenArray = arr => {
  const result = []
  if (Array.isArray(arr)) {
    arr.forEach(el => {
      Object.keys(el).forEach(key => {
        if (Array.isArray(el[key])) {
          result.push(...flattenArray(el[key]))
        }
      })
      if (Array.isArray(el)) {
        result.push(...flattenArray(el))
      } else {
        result.push(el)
      }
    })
  }
  return result
}

export const orderList = (list, key = 'name', config) => {
  const useOrderBy =
    !config ||
    [3].includes(config?.variant_id) ||
    [48, 49, 96, 97, 98, 110, 111, 113].includes(config.id)

  const filtered = list?.filter(l => ![-1].includes(l.id))
  const ordered = useOrderBy ? orderBy(filtered, key, 'asc') : filtered
  return [...list?.filter(l => [-1].includes(l.id)), ...ordered]
}
