import React from 'react'
import PropTypes from 'prop-types'
import {MdClose} from 'react-icons/md'
import isArray from 'lodash/isArray'
import memoize from 'lodash/memoize'
import {
  AgBox,
  AgButton,
  AgFlex,
  AgText,
  AgImage,
  useOnClickOutside,
} from '@aghealth/ag-components'
import {domToPng, getSelectedOne} from '../../../utils'
import {selectHasDashboardV2} from '../../../utils/selectors'
import {
  ToggleSwitch,
  Checkbox,
  CheckboxList,
  ScrollY,
  RadioButtonGroup,
} from '../../../components'
import DownloadMenu from './DownloadMenu'
import AddToDashboardButton from './AddToDashboardButton'

import bar from '../../../assets/bar.png'
import trend_multi from '../../../assets/trend_multi.png'
import trend_single from '../../../assets/trend_single.png'
import settings from '../../../assets/settings.png'
import patient from '../../../assets/patient.png'
import controls_supply from '../../../assets/controls_supply.png'
import group from '../../../assets/group.png'
import {useSelector} from 'react-redux'

const icons = {
  bar,
  line: trend_single,
  lines: trend_multi,
  bars: group,
  48: patient,
  49: controls_supply,
}

const CheckboxStyles = {
  Label: {
    fontWeight: 'normal',
  },
}

const createCheckboxListStyles = name => ({
  Root: {
    variant: 'scrollbars.y',
  },
  Checkbox: {
    Root: {
      pl: '30px',
      py: 2,
    },
    Input: {
      'data-testid': name,
    },
    Label: {
      ml: '10px',
      fontWeight: 'normal',
    },
  },
})

const createRadioButtonGroupStyles = name => ({
  RadioButton: {
    Root: {
      py: 2,
    },
    Input: {
      'data-testid': name,
    },
    Label: {
      ml: '10px',
      fontWeight: 'normal',
    },
  },
})

const OptionsCheckboxListStyles = createCheckboxListStyles('option')
const PhysicianCheckboxListStyles = createCheckboxListStyles('physician')
const PercentileTypesRadioButtonGroupStyles =
  createRadioButtonGroupStyles('percentile-type')

const IconStyles = {
  height: (_, props) =>
    [49, 48].includes(props.categoryId) || props.icon === 'bars' ? 18 : 14,
  width: (_, props) =>
    [48].includes(props.categoryId) ? 14 : props.icon === 'bars' ? 17 : 18,
  filter: (_, props) =>
    !props.selected
      ? 'brightness(0) saturate(100%) invert(36%) sepia(28%) saturate(6186%) hue-rotate(137deg) brightness(91%) contrast(86%)'
      : 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(27deg) brightness(103%) contrast(101%)',
}

const everySelected = memoize(list => list.every(item => item.selected))

const Controls = ({
  categories,
  onClickCategory,
  options,
  disableOptions,
  onCheckOption,
  physicians,
  onCheckPhysician,
  organizations,
  onCheckOrganization,
  showNumbers,
  onToggleShowNumbers,
  showPhysicianNames,
  onToggleShowPhysicianNames,
  groupBySupplyCategory,
  onToggleGroupBySupplyCategory,
  showPercentiles,
  onToggleShowPercentiles,
  chartRef,
  isDownload,
  isExport,
  onDownloadCaseLevelData,
  hasSupplyCategories,
  hasChartTotals,
  hasPercentiles,
  hasManufacturers,
  groupByManufacturer,
  onToggleGroupByManufacturer,
  hasSupplyDetails,
  onToggleShowAllCaseSupplyCosts,
  showAllCaseSupplyCosts,
  onToggleHideCaseRemovalButtons,
  hideCaseRemovalButtons,
  hasShowCosts,
  showCosts,
  onToggleShowCosts,
  table,
  procedureGroupName,
  comparisonTypes,
  onSelectComparisonType,
}) => {
  const ref = React.useRef()

  const [showOptions, setShowOptions] = React.useState(false)
  const handleToggleShowOptions = React.useCallback(() => {
    setShowOptions(prevShowOptions =>
      disableOptions ? false : !prevShowOptions,
    )
  }, [disableOptions])
  const handleCloseOptions = React.useCallback(() => {
    setShowOptions(false)
  }, [])
  const handleOnDownloadImage = React.useCallback(() => {
    const _chartRef = chartRef.current.cloneNode(true)
    const chartTitle = _chartRef.querySelector('#chart-title')

    const procedureGroupTitle = document.createElement('div')
    procedureGroupTitle.textContent = `${procedureGroupName}`
    procedureGroupTitle.style.paddingTop = '4px'
    procedureGroupTitle.style.paddingBottom = '16px'
    procedureGroupTitle.style.fontSize = '16px'
    procedureGroupTitle.style.paddingLeft = '16px'
    procedureGroupTitle.style.fontWeight = 'bold'

    chartTitle.appendChild(procedureGroupTitle)

    document.body.appendChild(_chartRef)
    domToPng(_chartRef, 'chart.png', {
      height: 1000,
      bgcolor: 'white',
      filter: node => {
        if (
          node.nodeType === 1 &&
          node.getAttribute('data-testid') === 'chip-label'
        ) {
          node.style.whiteSpace = 'nowrap'
        }
        // Check if node is an element
        if (node.nodeType === 1) {
          // 1 is the nodeType for elements
          // Exclude nodes with the 'data-html2canvas-ignore' attribute
          return !node.hasAttribute('data-html2canvas-ignore')
        }
        return true // include non-element nodes
      },
    })
      .then(() => {
        document.body.removeChild(_chartRef)
      })
      .catch(() => {
        document.body.removeChild(_chartRef)
      })
  }, [chartRef, procedureGroupName])
  useOnClickOutside(ref, handleCloseOptions, document)
  const isNonStaff = !useSelector(state => state.config?.user?.is_staff)
  const currentOrganizations = useSelector(
    state => state.config?.user?.organizations,
  )
  const selectedOrganization = getSelectedOne(currentOrganizations)
  const isDemo = selectedOrganization.demo
  const hasDashboardV2 = useSelector(selectHasDashboardV2)
  return (
    <AgFlex
      flexDirection="column"
      position="relative"
      height="40px"
      data-html2canvas-ignore
    >
      <AgFlex>
        {categories &&
          categories.length > 1 &&
          categories.map((category, index) => {
            const key = [48, 49].includes(category.id)
              ? category.id
              : category.icon
            const icon = icons[key]
            const label = [48, 49].includes(category.id)
              ? category.name
              : undefined
            return (
              <AgButton
                data-testid="category"
                key={category.id}
                border="base"
                bg={category.selected ? 'btn-bg-green' : '#FFFFFF'}
                onClick={() => onClickCategory(category)}
                height="44px"
                px="16px"
                borderRadius="sm"
                borderTopLeftRadius={index === 0 ? 'small' : '0px'}
                borderBottomLeftRadius={index === 0 ? 'small' : '0px'}
                borderTopRightRadius={
                  index === categories.length - 1 ? 'small' : '0px'
                }
                borderBottomRightRadius={
                  index === categories.length - 1 ? 'small' : '0px'
                }
                borderRight={index === categories.length - 1 ? 'base' : '0px'}
                borderColor="btn-border-green"
              >
                <AgFlex alignItems="center" width="100%">
                  {icon && (
                    <AgImage
                      src={icon}
                      categoryId={category.id}
                      selected={category.selected}
                      icon={category.icon}
                      sx={IconStyles}
                    />
                  )}
                  {label && (
                    <AgText
                      pl="5px"
                      fontSize="14px"
                      fontWeight="md"
                      color={category.selected ? 'white' : 'btn-bg-green'}
                    >
                      {label}
                    </AgText>
                  )}
                </AgFlex>
              </AgButton>
            )
          })}
        <AgButton
          data-testid="chart-options"
          marginLeft="20px"
          borderRadius="sm"
          border="base"
          borderColor="btn-border-green"
          height="44px"
          px="16px"
          bg="#FFFFFF"
          onClick={handleToggleShowOptions}
          opacity={disableOptions ? 0.6 : 1}
          sx={{cursor: disableOptions ? 'default' : 'pointer'}}
        >
          <AgFlex alignItems="center">
            <AgImage
              src={settings}
              sx={{
                size: 18,
                filter:
                  'brightness(0) saturate(100%) invert(36%) sepia(28%) saturate(6186%) hue-rotate(137deg) brightness(91%) contrast(86%)',
              }}
            />
            <AgText
              pl="5px"
              fontSize="14px"
              fontWeight="md"
              color="btn-bg-green"
            >
              Chart Options
            </AgText>
          </AgFlex>
        </AgButton>
        {!(isNonStaff && isDemo) && (
          <DownloadMenu
            table={table}
            tableId={table.id}
            onDownloadChartImage={handleOnDownloadImage}
            onDownloadCaseLevelData={onDownloadCaseLevelData}
            export={isExport}
            download={isDownload}
          />
        )}
        {hasDashboardV2 && <AddToDashboardButton />}
      </AgFlex>

      {showOptions ? (
        <AgFlex
          ref={ref}
          flexDirection="column"
          border="1px outset darkgray"
          width="305px"
          right="139.27px"
          marginTop="43px"
          borderRadius="sm"
          position="absolute"
          zIndex="base"
          bg="#F8F8F8"
          maxHeight="550px"
          boxShadow="depth24"
          sx={{
            variant: 'scrollbars.y-hide',
          }}
        >
          <AgFlex
            justifyContent="flex-end"
            alignItems="center"
            marginTop="15px"
            paddingLeft="15px"
            paddingRight="15px"
          >
            <AgBox onClick={handleCloseOptions}>
              <MdClose size={40} cursor="pointer" />
            </AgBox>
          </AgFlex>
          {options?.title && options?.values?.length > 0 && (
            <AgFlex
              flexDirection="column"
              paddingLeft="15px"
              paddingRight="15px"
            >
              <AgText fontSize="14px" fontWeight="bold" marginBottom={2}>
                {options.title}
              </AgText>
              <ScrollY maxHeight={150} width="100%">
                <Checkbox
                  name={`All ${options.title}`}
                  selected={everySelected(options.values)}
                  onChange={() => onCheckOption(options.values)}
                  styles={CheckboxStyles}
                />
                <CheckboxList
                  list={options.values}
                  onChange={onCheckOption}
                  styles={OptionsCheckboxListStyles}
                />
              </ScrollY>
            </AgFlex>
          )}
          {hasSupplyDetails && (
            <>
              <AgFlex
                justifyContent="space-between"
                marginTop="20px"
                paddingBottom="10px"
                paddingLeft="15px"
                paddingRight="15px"
                borderBottom="1px solid darkgray"
              >
                <AgText fontSize="14px">Show All Case Supply Costs</AgText>
                <ToggleSwitch
                  onClick={() =>
                    onToggleShowAllCaseSupplyCosts(!showAllCaseSupplyCosts)
                  }
                  on={showAllCaseSupplyCosts}
                />
              </AgFlex>
              <AgFlex
                justifyContent="space-between"
                marginTop="20px"
                paddingBottom="10px"
                paddingLeft="15px"
                paddingRight="15px"
                borderBottom="1px solid darkgray"
              >
                <AgText fontSize="14px">Hide Case Removal Buttons</AgText>
                <ToggleSwitch
                  onClick={() =>
                    onToggleHideCaseRemovalButtons(!hideCaseRemovalButtons)
                  }
                  on={hideCaseRemovalButtons}
                />
              </AgFlex>
            </>
          )}
          {hasChartTotals && (
            <AgFlex
              justifyContent="space-between"
              marginTop="20px"
              borderBottom="1px solid darkgray"
              paddingBottom="20px"
              paddingLeft="15px"
              paddingRight="15px"
            >
              <AgText fontSize="14px">Show Chart Totals</AgText>
              <ToggleSwitch onClick={onToggleShowNumbers} on={showNumbers} />
            </AgFlex>
          )}
          {hasSupplyCategories && (
            <AgFlex
              justifyContent="space-between"
              marginTop="20px"
              borderBottom="1px solid darkgray"
              paddingBottom="20px"
              paddingLeft="10px"
              paddingRight="10px"
              marginBottom="20px"
            >
              <AgText fontSize="14px">Group By Supply Category</AgText>
              <ToggleSwitch
                onClick={onToggleGroupBySupplyCategory}
                on={groupBySupplyCategory}
              />
            </AgFlex>
          )}
          {hasManufacturers && (
            <AgFlex
              justifyContent="space-between"
              marginTop="20px"
              borderBottom="1px solid darkgray"
              paddingBottom="20px"
              paddingLeft="10px"
              paddingRight="10px"
              marginBottom="20px"
            >
              <AgText fontSize="14px">Group By Manufacturer</AgText>
              <ToggleSwitch
                onClick={onToggleGroupByManufacturer}
                on={groupByManufacturer}
              />
            </AgFlex>
          )}
          {hasPercentiles && (
            <AgFlex
              justifyContent="space-between"
              marginTop="20px"
              borderBottom="1px solid darkgray"
              paddingBottom="20px"
              paddingLeft="15px"
              paddingRight="15px"
            >
              <AgText fontSize="14px">Show Percentiles</AgText>
              <ToggleSwitch
                onClick={onToggleShowPercentiles}
                on={showPercentiles}
              />
            </AgFlex>
          )}
          {comparisonTypes && comparisonTypes?.length > 0 && (
            <AgFlex
              flexDirection="column"
              marginTop="20px"
              paddingLeft="15px"
              paddingRight="15px"
              paddingBottom="15px"
              borderBottom="1px solid darkgray"
            >
              <AgText fontSize="14px" marginBottom="15px">
                Percentile Type
              </AgText>

              <RadioButtonGroup
                items={comparisonTypes}
                onClick={onSelectComparisonType}
                styles={PercentileTypesRadioButtonGroupStyles}
              />
            </AgFlex>
          )}
          {hasShowCosts && (
            <AgFlex
              justifyContent="space-between"
              marginTop="20px"
              borderBottom="1px solid darkgray"
              paddingBottom="20px"
              paddingLeft="15px"
              paddingRight="15px"
            >
              <AgText fontSize="14px">Show Costs</AgText>
              <ToggleSwitch onClick={onToggleShowCosts} on={showCosts} />
            </AgFlex>
          )}
          {isArray(physicians) ? (
            <AgFlex flexDirection="column">
              <AgFlex
                justifyContent="space-between"
                marginTop="20px"
                borderBottom="1px solid darkgray"
                paddingBottom="20px"
                paddingLeft="15px"
                paddingRight="15px"
                marginBottom="20px"
              >
                <AgText fontSize="14px">Show Physician Names</AgText>
                <ToggleSwitch
                  onClick={onToggleShowPhysicianNames}
                  on={showPhysicianNames}
                />
              </AgFlex>
              <AgFlex
                flexDirection="column"
                paddingLeft="15px"
                paddingRight="15px"
                paddingBottom="15px"
              >
                <AgText fontSize="14px" fontWeight="bold" marginBottom="15px">
                  Physicians
                </AgText>
                <ScrollY width="100%" maxHeight={175} pb={2}>
                  <Checkbox
                    name="All Physicians"
                    selected={everySelected(physicians)}
                    onChange={() => onCheckPhysician(physicians)}
                    styles={CheckboxStyles}
                  />
                  <CheckboxList
                    list={physicians}
                    onChange={onCheckPhysician}
                    styles={PhysicianCheckboxListStyles}
                  />
                </ScrollY>
              </AgFlex>
            </AgFlex>
          ) : null}

          {organizations ? (
            <AgFlex flexDirection="column">
              <AgFlex
                flexDirection="column"
                marginTop="20px"
                paddingLeft="15px"
                paddingRight="15px"
                paddingBottom="15px"
              >
                <AgText fontSize="14px" fontWeight="bold" marginBottom="15px">
                  Organizations
                </AgText>
                <ScrollY width="100%" maxHeight={175} pb={2}>
                  <Checkbox
                    name="All Organizations"
                    selected={everySelected(organizations)}
                    onChange={() => onCheckOrganization(organizations)}
                    styles={CheckboxStyles}
                  />
                  <CheckboxList
                    list={organizations}
                    onChange={onCheckOrganization}
                    styles={PhysicianCheckboxListStyles}
                  />
                </ScrollY>
              </AgFlex>
            </AgFlex>
          ) : null}
        </AgFlex>
      ) : null}
    </AgFlex>
  )
}

Controls.propTypes = {
  chartRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  table: PropTypes.object,
  isExport: PropTypes.bool,
  isDownload: PropTypes.bool,
  categories: PropTypes.array,
  onClickCategory: PropTypes.func,
  options: PropTypes.shape({
    title: PropTypes.string,
    values: PropTypes.array,
  }),
  onCheckOption: PropTypes.func,
  onCheckPhysician: PropTypes.func,
  disableOptions: PropTypes.bool,
  showNumbers: PropTypes.bool,
  showPhysicianNames: PropTypes.bool,
  physicians: PropTypes.array,
  organizations: PropTypes.array,
  onCheckOrganization: PropTypes.func,
  groupBySupplyCategory: PropTypes.bool,
  groupByManufacturer: PropTypes.bool,
  onToggleGroupBySupplyCategory: PropTypes.func,
  onToggleShowNumbers: PropTypes.func,
  onToggleShowPhysicianNames: PropTypes.func,
  onToggleGroupByManufacturer: PropTypes.func,
  onDownloadCaseLevelData: PropTypes.func,
  hasSupplyCategories: PropTypes.bool,
  hasChartTotals: PropTypes.bool,
  hasManufacturers: PropTypes.bool,
  onToggleHideCaseRemovalButtons: PropTypes.func,
  hideCaseRemovalButtons: PropTypes.bool,
  hasShowCosts: PropTypes.bool,
  showCosts: PropTypes.bool,
  onToggleShowCosts: PropTypes.func,
  procedureGroupName: PropTypes.string,
  comparisonTypes: PropTypes.array,
  onSelectComparisonType: PropTypes.func,
}

const MemoizedControls = React.memo(Controls)

MemoizedControls.propTypes = Controls.propTypes

export default MemoizedControls
