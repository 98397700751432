import {
  DEFAULT_FAVORITE_FETCH_OFFSET,
  FAVORITE_FETCH_BATCH_SIZE,
} from '../pages/dashboard-v2/constants'

import omit from 'lodash/omit'

import {
  toJson,
  baseURL,
  getHeaders,
  massageFilters,
  withChartsConfig,
  withMassagePercentiles,
} from './utils'
import {toQueryString} from '../utils'

const endpoint = `${baseURL}/api/dashboard/v2`

export const createFavorite = async payload => {
  const response = await fetch(`${endpoint}/favorites`, {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify(payload),
  })
  const json = await response.json()

  if (!response.ok)
    return Promise.reject({status: response.status, response: json})

  return Promise.resolve(json)
}

const fetchFavoriteList = toJson(async options => {
  const {limit, offset, refreshDate, signal} = options

  return fetch(
    `${endpoint}/favorites?limit=${limit ?? FAVORITE_FETCH_BATCH_SIZE}&offset=${
      offset ?? DEFAULT_FAVORITE_FETCH_OFFSET
    }&refresh_date=${refreshDate}`,
    {
      signal,
      headers: await getHeaders(),
    },
  )
})

export const fetchFavorites = async options => {
  const favorites = await fetchFavoriteList(options)

  return {
    ...favorites,
    data: favorites.data.map(favorite => ({
      ...favorite,
      filters: {
        ...favorite.filters,
        ...massageFilters(
          omit(favorite.filters, ['all_inpatient', 'all_outpatient']),
        ),
      },
    })),
  }
}

export const deleteFavorite = async id => {
  const response = await fetch(`${endpoint}/favorites/${id}`, {
    headers: await getHeaders(),
    method: 'DELETE',
  })
  const json = await response.text()

  if (!response.ok)
    return Promise.reject({status: response.status, response: json})

  return Promise.resolve(json)
}

export const fetchTopOpportunity = async options => {
  const {search, signal} = options
  const topOpportunityEndpoint = `${endpoint}/insights`
  const response = await fetch(topOpportunityEndpoint.concat(search), {
    headers: await getHeaders(),
    signal,
  })
  const {data, time_periods, ...json} = await response.json()
  const payload = {
    ...json,
    data,
    time_periods,
  }
  data.forEach(d => {
    d.date_of_surgery_max = time_periods?.find(
      item => item?.department_id === d.department_id,
    )?.max
  })
  return Promise.resolve(payload)
}

export const fetchFavoriteDetails = withMassagePercentiles(
  withChartsConfig(
    toJson(async options => {
      const {favoriteId, signal, payload} = options

      const params = toQueryString(payload)

      const url = `${endpoint}/favorites/${favoriteId}`

      return fetch(params ? url.concat(params) : url, {
        signal,
        headers: await getHeaders(),
      })
    }),
  ),
)

export const updateChart = withMassagePercentiles(
  withChartsConfig(
    toJson(async options => {
      const {favoriteId, signal, payload} = options

      return fetch(`${endpoint}/favorites/${favoriteId}`, {
        signal,
        headers: await getHeaders(),
        method: 'PATCH',
        body: JSON.stringify(payload),
      })
    }),
  ),
)
