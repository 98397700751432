import {createSelector} from 'reselect'

const getActiveUrl = routes => {
  const active = routes.filter(route => route.selected)[0]
  return active?.url ? active.url : ''
}

const getDefaultUrl = routes => {
  const default_dept = routes.filter(route => route.default)[0]
  return default_dept?.url ? default_dept.url : ''
}

export const selectRedirectTo = createSelector(
  state => state.subnav,
  subnav => `${getActiveUrl(subnav.routes)}${getActiveUrl(subnav.subroutes)}`,
)

export const selectTopInsightsUrl = createSelector(
  state => state.subnav,
  subnav => `/top-insights-v2${getActiveUrl(subnav.subroutes)}`,
)

export const selectHomeUrl = createSelector(
  state => state.subnav,
  state => state.config?.feature_flags?.includes(13),
  (subnav, hasDashboardV2) => {
    return hasDashboardV2
      ? '/dashboard-v2'
      : `${getDefaultUrl(subnav.routes)}${getActiveUrl(subnav.subroutes)}`
  },
)

export const selectIsSystemView = createSelector(
  state => state.config?.user?.organizations,
  organizations =>
    organizations?.filter(o => o.selected && o.system)?.length > 0,
)

export const selectIsDemoView = createSelector(
  state => state.config?.user?.organizations,
  organizations => organizations?.filter(o => o.selected && o.demo)?.length > 0,
)

export const selectDefaultDepartment = createSelector(
  state => state.config?.departments,
  departments =>
    departments?.find(department => department.default) || {id: undefined},
)

export const selectGAUserId = createSelector(
  state => state.config.user,
  user => {
    if (user.is_staff) return undefined
    const orgId = user.organizations
      .filter(org => org.selected)
      .map(org => org.id)
      .join()
    return `${user.id}-${orgId}`
  },
)

export const selectIsPAC403 = createSelector(
  state => state.pac,
  pac => pac && pac.error && pac.error.status === 403,
)

export const selectSubrouteUrls = createSelector(
  state => state.subnav,
  subnav => subnav?.subroutes?.map(s => s.url),
)

export const selectIsInFacilityType = createSelector(
  selectIsSystemView,
  state => state.config.user.organization_type_id,
  (isSystem, organization_type_id) => isSystem || organization_type_id === 1,
)

export const selectOrganization = createSelector(
  state => state.config.user.organizations,
  organizations => organizations.filter(o => o.selected)[0],
)

export const selectNoDepartments = createSelector(
  state => state.config.departments,
  departments => !departments?.length > 0,
)

export const selectChatbotUserName = createSelector(
  state => state.config.user,
  user => (user?.first_name ? `${user.first_name} ${user.last_name}` : 'You'),
)

export const selectHasChatbot = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(11),
)

export const selectHasDashboardV2 = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(13),
)

export const selectHasGoals = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(14),
)

export const selectHasNavigationV2 = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(15),
)

export const selectRefreshDate = createSelector(
  state => state.config.user.organizations,
  organizations =>
    organizations
      .filter(organization => organization.selected)
      .map(organization => organization.refresh_date)
      .join(),
)

export const selectIsStaff = createSelector(
  state => state?.config?.user,
  user => user?.is_staff,
)

export const selectDepartmentSubrouteRegex = createSelector(
  state => state.config.departments.map(department => department.param),
  departmentParams => departmentParams.join('|'),
)

export const selectHasProjectGuidanceTool = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(16),
)

export const selectHasBilling = createSelector(
  state => state.config.feature_flags,
  feature_flags => feature_flags?.includes(5),
)
