import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import {createERASConfig} from '../pages/explore-data/utils/eras/config'
import {createConfig} from '../pages/explore-data/utils/config-factory'
import supply from '../assets/supply.png'
import doctor from '../assets/doctor.png'
import bulletin from '../assets/bulletin.png'
import hospital from '../assets/hospital.png'
import pie from '../assets/pie.png'
import rehab from '../assets/rehab.png'
import timer from '../assets/timer.png'
import wheelchair from '../assets/wheelchair.png'

export const baseURL = `${
  process.env.NODE_ENV === 'test'
    ? process.env.REACT_APP_TEST_BASE_URL
    : process.env.REACT_APP_BASE_URL
}`
export const baseURLV1 = `${
  process.env.NODE_ENV === 'test'
    ? process.env.REACT_APP_TEST_BASE_URL
    : process.env.REACT_APP_V1_BASE_URL
}`

export const toJson =
  fetch =>
  async (...args) => {
    const response = await fetch(...args)
    const json = await response.json()
    if (!response.ok) return Promise.reject({status: response.status})
    return Promise.resolve(json)
  }

export const getHeaders =
  process.env.NODE_ENV === 'test'
    ? () => Promise.resolve({})
    : require('@aghealth/ag-components').getAuthHeaders

export const getSearchParams = params =>
  Object.entries(params)
    .filter(([, value]) => !isEmpty(value))
    .reduce((o, [key, value]) => {
      o[key] = value
      return o
    }, {})

const setFilterType = (data, type) => {
  if (isArray(data) && !data.some(isString))
    return data.map(d => ({...d, type}))
  if (isArray(data?.values))
    return {...data, values: data.values.map(v => ({...v, type}))}
  return data
}

export const massageFilters = filters =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = setFilterType(value, key)
    return acc
  }, {})

export const withMassageFilters = request => options =>
  request(options).then(response => {
    const filters = response.filters ? massageFilters(response.filters) : {}
    return Promise.resolve({...response, filters})
  })

export const withMassagePercentiles = request => options =>
  request(options).then(response => {
    const percentiles = response?.percentiles?.full_percentiles
      ? response?.percentiles?.full_percentiles
      : response?.percentiles
    const all_percentiles = response?.percentiles
    return Promise.resolve({...response, percentiles, all_percentiles})
  })

const Cost = {
  1: hospital,
  'Cement Type Use': doctor,
  9: supply,
  10: doctor,
  11: hospital,
  'Supply Details': supply,
}

const Outcomes = {
  12: pie,
  13: bulletin,
  14: bulletin,
  'PRO MCID': bulletin,
}

const Process = {
  7: timer,
  8: timer,
  15: timer,
  16: wheelchair,
  17: rehab,
}
const QualityMetrics = {
  19: bulletin,
  20: bulletin,
  21: bulletin,
}

const METRIC_ICONS = {
  ...Cost,
  ...Outcomes,
  ...Process,
  ...QualityMetrics,
}

const METRICS_WITH_NO_DATA = [2, 3, 4, 5, 6]

export const massageMetrics = metrics => {
  const summary = metrics?.filter(m => m.id === 6)

  return summary
    ? [
        ...metrics?.filter(m => !METRICS_WITH_NO_DATA.includes(m.id)),
        ...summary,
      ].map(m => ({...m, icon: METRIC_ICONS[m.id]}))
    : []
}

export const withChartsConfig =
  request =>
  async (...args) => {
    const response = await request(...args)

    const payload = {
      ...response,
      config: response?.config?.id
        ? createConfig(response.config)
        : createERASConfig(response.config),
    }
    return Promise.resolve(payload)
  }
